#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  background-color: #000;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

/* Header  */

.navbar {
  height: 130px;
}

/* .navbar-brand img {
  height: 55px;
} */

.navbar .nav-link {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  padding: 0 !important;
  text-align: center;
  margin: 0 5px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.navbar .nav-link:hover {
  color: #416bff;
  transition: all 0.2s ease-in-out;
}

.connect-btn {
  display: inline-flex;
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  color: #fff;
  background: #416bff;
  border-radius: 20px;
  height: 52px;
  width: 185px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.connect-btn:hover {
  background: transparent;
  color: #416bff;
  border: 3px solid #416bff;
  transition: all 0.3s ease-in-out;
}

.navbar-collapse.collapsing {
  transition: none !important;
}

/* Banner */
.banner {
  padding: 75px 0;
}
.banner h1 {
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  color: #ffffff;
}

.banner p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.btnPrimary {
  display: inline-flex;
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  color: #fff;
  background: #416bff;
  border-radius: 20px;
  height: 52px;
  width: 185px;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.btnPrimary:hover {
  background: transparent;
  color: #416bff;
  border: 3px solid #416bff;
  transition: all 0.3s ease-in-out;
}

.transition-all {
  transition-duration: 0.15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.iconify {
  color: white;
  fill: white;
  font-size: 40px;
  transition: all 0.3s ease-in-out;
}

.iconify:hover {
  color: #416bff;
  fill: #416bff;
  transition: all 0.3s ease-in-out;
}

.eco {
  background: #1d2027;
  border-radius: 30px;
  padding: 23px 25px;
}
.icon {
  text-align: center;
  margin-bottom: 30px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#ecosys h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}
#ecosys h3 {
  font-weight: 600;
  font-size: 23px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}

.container-fluid {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 40px;
}
.roadmap h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 80px;
}
.roadmap p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 40px;
}
.e-banner {
  background: linear-gradient(258.62deg, #783171 7.92%, #1d2027 96.53%);
  border-radius: 30px 30px 0px 0px;
  padding: 100px 0;
  padding-bottom: 130px;
}

.s-banner {
  background: linear-gradient(254.57deg, #15831a -0.79%, #1d2027 98.31%);
  border-radius: 30px 30px 0px 0px;
  padding: 100px 0;
  margin-top: -30px;
  padding-bottom: 130px;
}
.u-banner {
  background: linear-gradient(254.57deg, #3850d0 -0.79%, #1d2027 98.31%);
  border-radius: 30px 30px 0px 0px;
  padding: 100px 0;
  margin-top: -30px;
  padding-bottom: 130px;
}
.l-banner {
  background: linear-gradient(254.57deg, #e4e846 -0.79%, #1d2027 98.31%);
  border-radius: 30px 30px 0px 0px;
  padding: 100px 0;
  margin-top: -30px;
}

@media (max-width: 1224px) {
  .navbar-nav {
    flex-direction: row;
  }

  .navbar {
    height: auto;
  }

  .navbar .nav-link {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1020px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 111;
    display: block !important;
  }

  .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
    color: #416bff;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.coming {
  height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming h1 {
  color: white;
}

footer {
  background: #000000;
  border-radius: 0px 0px 5px 5px;
  padding-top: 60px;
  padding-bottom: 30px;
}
footer h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 30px;
  text-transform: uppercase;
}
footer li {
  margin-bottom: 25px;
}
footer li a,
footer li span {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  position: relative;
}
footer li a:hover {
  color: #416bff;
  transition: all 0.2s ease-in-out;
}
footer li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #416bff;
  height: 2px;
  width: 0;
  transition: all 0.2s ease-in-out;
  left: 0;
  right: 0;
  margin: 0 auto;
}
footer li a:hover::after {
  width: 100%;
  transition: all 0.2s ease-in-out;
}
footer .border-top {
  border-top: 1px solid #1a1a1a !important;
}
footer p {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #787878;
  margin-bottom: 0;
}

footer .disclaimer {
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
}

footer .disclaimer .address {
  font-weight: 700;
}
@media (max-width: 767px) {
  .banner {
    padding-top: 20px;
  }
  .navbar-brand img {
    height: 35px;
  }

  .banner h1 {
    font-size: 34px;
    line-height: 45px;
    padding-top: 30px;
  }

  .banner p {
    font-size: 18px;
  }

  .banner .btnPrimary,
  .banner .btn-outline-Primary {
    width: 200px;
    height: 60px;
    max-height: 60px;
  }
  #ecosys h3 {
    font-size: 22px;
  }
  .e-banner,
  .s-banner,
  .l-banner,
  .u-banner {
    padding: 70px 0;
    padding-bottom: 100px;
  }
  .roadmap h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .container-fluid {
    padding: 0 20px;
  }

  footer h5 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 25px;
  }
  footer li a,
  footer li span {
    font-size: 18px;
    line-height: 20px;
  }
  footer li {
    margin-bottom: 20px;
  }
  .row-col-50 {
    flex: 0 0 auto;
    width: 50%;
  }
  .t-center {
    text-align: center;
  }
}

.post-title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
  color: white;
}
h2.post-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 60px;
  color: white;
}
.bg-grad h4 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 40px;
  line-height: 70px;
  color: white;
}

.bg-grad h3 {
  color: white;
  font-weight: 700;
  font-size: 26px;
}

.bg-grad p {
  color: #fff;
  font-size: 16px;
  margin: 25px 0;
  font-weight: 400;
  line-height: 20px;
}
.bg-grad h6 {
  color: white;
  font-weight: 800;
}
.bg-grad li {
  color: white;
}
.bg-grad h5 {
  color: white;
  font-weight: 600;
  font-size: 22px;
}

.bg-grad a {
  text-decoration: underline;
}

.img-fluid {
  border-radius: 30px 0 0 30px;
}
